import React from "react"
import { graphql } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"

import WiglyLineVertical from "../images/wigly-line-vertical.svg"
import TicketGroup from "../components/festival/ticket-group"
import Layout from "../components/layout/layout"
import PageTransition from "../components/transition/page"
import FestivalHeader from "../components/festival/festival-header"
import FestivalNavigation from "../components/festival/festival-navigation"
import FestivalBackground from "../components/festival/festival-background"
import SEO from "../components/seo/seo"
import PartnerGroup from "../components/festival/partner-group"
import SectionTitle from "../components/layout/section-title"
import Block from "../components/block/block"
import DoubleBlock from "../components/block/double"
import Button from "../components/button/button"
import PromoBlock from "../components/block/promo"

class TicketsTemplate extends React.Component {

    constructor(props){
        super(props)
        this.pageTransition = React.createRef()
    }

    componentDidMount () {
      const { location } = this.props.data
      if (typeof window !== 'undefined' && location.eventbriteEventId) {
        const { location } = this.props.data
        window.EBWidgets.createWidget({
          widgetType: 'checkout',
          eventId: location.eventbriteEventId,
          modal: true,
          modalTriggerElementId: 'eventbrite-button'
        })
      }
    }

    animate(){
        this.pageTransition.current.play()
    }

    render(){

        const locale = this.props.pageContext.locale
        const { location } = this.props.data

        return (
            <Layout theme="light" path={this.props.path} locale={locale} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
                <SEO title={location.title} />
                <FestivalNavigation locale={locale} links={location.navigation.links} color={'#F5867B'} />
                <FestivalHeader wide={location.theme.widescreenHeaderImage.file.url} portrait={location.theme.portraitHeaderImage.file.url} animation={location.theme.animation.file.url}>
                  <div className={"container mx-auto py-20 sm:py-4 md:py-0 lg:py-20 text-center leading-none"}>
                    <p className={"font-formula-bold text-lg lg:text-2xl text-white uppercase tracking-wider"}>{`the drop festival ${location.title}`}</p>
                    <p className={"font-montserrat-extra-bold text-3xl md:text-7xl lg:text-9xl text-white uppercase"}>ticket info</p>
                  </div>
                </FestivalHeader>
                <FestivalBackground wide={location.theme.widescreenBackground.file.url}>
                  { location && location.presale && location.presale.visible && <PromoBlock {...location.presale} {...locale} /> }
                    <TicketGroup tickets={location.tickets} eventbriteEventId={location.eventbriteEventId} />
                    <div className={"container mx-auto md:px-8"}>
                      <SectionTitle title={location.ticketHeading} position={"center"} />
                      <div className={"flex flex-wrap mx-auto w-full md:w-3/5 -mt-12"}>
                        {
                          location.ticketDates.map((item,index) => {

                            let seperator = ''

                            if(index !== (location.ticketDates.length-1)){
                              seperator = (<img className={"hidden md:block absolute"} src={WiglyLineVertical} style={{right:'0',top:'25%'}} alt="" />)
                            }

                            return(
                              <div className={"w-full mx-auto md:w-1/2 md:-mt-8 relative"} key={`item-${index}`}>
                                <Block {...item} align={'center'} />
                                {seperator}
                              </div>
                            )
                          })
                        }
                      </div>
                      <div className={"w-full text-center"}>
                        <a id="eventbrite-button" { ...(!location.eventbriteEventId ? { href: location.ticketCtaLink, target: '_blank', rel: 'noopener noreferrer' } : {}) }>
                          <Button label={"buy tickets now"} type={"solid"} />
                        </a>
                      </div>
                    </div>
                    <div className={"container mx-auto my-20 md:px-8"}>
                      <DoubleBlock {...location.ticketInfo} align={"left"} locale={locale} />
                    </div>
                    <div className={"container mx-auto my-20 md:px-8"}>
                      <DoubleBlock {...location.transportInfo} align={"right"} locale={locale} />
                    </div>
                    <PartnerGroup partners={location.partners} locale={locale} />
                </FestivalBackground>
                <TransitionPortal>
                  <PageTransition ref={this.pageTransition} />
                </TransitionPortal>
            </Layout>
        )

    }

}

export const pageQuery = graphql`
query TicketsPageQuery($slug: String!, $locale: String!) {
  location: contentfulLocation(slug: {eq: $slug}, node_locale: {eq: $locale}) {
    title
    slug
    date
    venue
    ticketCtaLink
    eventbriteEventId
    tickets{
      ...on ContentfulTicket{
        title
        slug
        age
        price
        details
        link
        tile{
          file{
            url
          }
        }
      }
    }
    ticketInfo{
      ...on ContentfulBlock{
        title
        slug
        images{
          file{
            url
          }
        }
        ctaText
        link
        content {
          json
        }
      }
    }
    ticketHeading
    ticketDates{
      ...on ContentfulBlock{
        title
        slug
        disclaimer
        images{
          file{
            url
          }
        }
        icon{
          file{
            url
          }
        }
        ctaText
        link
        content {
          json
        }
      }
    }
    transportInfo{
      ...on ContentfulBlock{
        title
        slug
        images{
          file{
            url
          }
        }
        ctaText
        link
        content {
          json
        }
      }
    }
    partners {
      ... on ContentfulPartner {
        slug
        logo {
          file {
            url
          }
        }
      }
    }
    theme {
      ... on ContentfulTheme {
        logo {
          file {
            url
          }
        }
        animation{
          file{
            url
          }
        }
        portraitBackground {
          file {
            url
          }
        }
        portraitHeaderImage {
          file {
            url
          }
        }
        widescreenBackground {
          file {
            url
          }
        }
        widescreenHeaderImage {
          file {
            url
          }
        }
        colorPrimary
        colorSecondary
      }
    }
    navigation{
      ...on ContentfulNavigation{
        links{
          ...on ContentfulLink{
            title
            href
          }
        }
      }
    }
  }
}
`

export default TicketsTemplate
