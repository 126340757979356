import React from "react"

import "./ticket-group.scss"
import TicketTile from "./ticket-tile"

class TicketGroup extends React.Component {

    render(){
        return(
            <div className={"container mx-auto my-20 px-2 md:px-8 relative"}>
                <div className={"flex flex-wrap ticket-tiles relative"}>
                    {
                        this.props.tickets.map((ticket, index) => {
                          const width = this.props.tickets.length === 6 ? '1/3' : '1/4' 
                            return (
                                <div className={`w-1/2 mb-4 md:mb-12 md:w-${width}`} key={`ticket-${index}`}>
                                    <TicketTile 
                                        eventbriteEventId={this.props.eventbriteEventId} 
                                        age={ticket.age} 
                                        details={ticket.details} 
                                        link={ticket.link} 
                                        price={ticket.price} 
                                        title={ticket.title} 
                                        file={ticket.tile.file.url} 
                                    />
                                </div>
                            )
                        })
                    }
                </div>
                <div className={"red-gradient-background"}></div>
            </div>
        )
    }

}

export default TicketGroup
