import React from "react"

import "./ticket-tile.scss"

class TicketTile extends React.Component {

    render(){
        return(
            <div>
                <a id="eventbrite-button" className={`square mx-auto`} style={{backgroundImage:`url(${this.props.file})`, cursor: 'pointer', display: 'block'}} { ...(!this.props.eventbriteEventId ? { href: this.props.link, target: '_blank', rel: 'noopener noreferrer' } : {}) }>
                    <div className={"hover-top"}>
                        <div className={"content  leading-none"}>
                            <p className={"font-montserrat-regular text-white text-xs md:text-sm tracking-wide"}>{this.props.age}</p>
                            <p className={"font-montserrat-extra-bold text-white text-sm md:text-lg tracking-widest"}>{this.props.title}</p>
                            <p className={"font-montserrat-extra-bold text-white text-xl md:text-3xl"}>{this.props.price}</p>
                            <p className={"font-montserrat-regular text-white text-xxs"}>{this.props.details}</p>
                        </div>
                    </div>
                    <div className={"absolute hover-background"}></div>
                </a>
            </div>
        )
    }
}

export default TicketTile
