import React from "react"
import Hyperlink from "../button/hyperlink"

import WiglyLine from "../../images/wigly-line.svg"
import Button from "../button/button"
import RichTextField from "../textfield/rich"
import "./promo.scss"

class PromoBlock extends React.Component {

    render(){

        const { title, ctaText, link, content, images, disclaimer, locale } = this.props

        return(
            <div className={"container mx-auto"}>

                <div className={"block lg:hidden my-12 px-4"}>
                    <div className={"promo-pattern-mobile py-4 mx-auto w-3/4 md:w-3/5"}></div>
                    <div>
                    {
                        images.map((img, index) => {
                            return(<img src={img.file.url} key={`img-${index}`}  className={"mx-auto w-full md:w-2/3"} alt="" />)
                        })
                    }
                    </div>
                    <div className={"promo-text bg-white border-4 border-solid border-reef text-center px-4 py-6 -mt-4 w-11/12 md:w-3/4 mx-auto"}>
                        <div className={"leading-none mb-4"}>
                            <h3 className={"font-agrandir text-xl"}>{title}</h3>
                        </div>
                        <div>
                            <img src={WiglyLine} className={"mx-auto my-4"} alt=""/>
                        </div>
                        <div>
                            <RichTextField json={content.json} locale={locale} />
                        </div>
                    </div>
                    <div className={"-mt-4 mx-auto text-center relative"} style={{zIndex:'1000'}}>
                        <Hyperlink href={link}>
                            <Button label={ctaText} />
                        </Hyperlink>
                    </div>
                    <div className={"text-center mx-auto px-4 mt-4"}>
                    {disclaimer && (<span className={"font-montserrat-regular text-xs text-gray-400"}>{disclaimer}</span>)}
                    </div>
                </div>

                <div className={"hidden lg:block container mx-auto my-20 px-8"}>
                    <div className={"relative pt-20"}>
                        <div className={"promo-text bg-white border-4 border-solid border-reef w-7/12 px-12 py-20 mt-12"}>
                            <div className={"leading-none presale-title"}>
                                <h3 className={"font-agrandir text-3xl"}>{title}</h3>
                            </div>
                            <div className={"my-2"}>
                                <img src={WiglyLine} alt=""/>
                            </div>
                            <div className={"my-1 py-1 pr-20"}>
                                <RichTextField json={content.json} locale={locale} />
                                </div>
                            <div className={"my-4 py-1"}>
                                <Hyperlink href={link}>
                                    <Button label={ctaText} />
                                </Hyperlink>
                            </div>
                            {disclaimer && (<span className={"font-montserrat-regular text-xs text-gray-400"}>{disclaimer}</span>)}
                        </div>
                        <div className={"promo-image"}>
                        {
                            images.map((img, index) => {
                                return(<img src={img.file.url} key={`img-${index}`} alt=""/>)
                            })
                        }
                        </div>
                        <div className={"promo-pattern"}></div>
                    </div>
                </div>

            </div>
        )
    }

}

export default PromoBlock
